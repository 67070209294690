import React, { useEffect, useState, useRef, useCallback } from "react"
import { graphql, Link } from "gatsby"
import SEO from "../../components/seo"
import { useDispatch, useSelector } from "react-redux"

// 🎨 UI
import { Box, Heading } from "@chakra-ui/react"
import ProjectPageWrapper from "../../components/Contents/ProjectPageWrapper"
import ProjectPageCard from "../../components/Cards/ProjectPageCard"
import ProjectScene from "../../components/Contents/ProjectScene"
import ScrollTopButton from "../../components/ScrollTopButton"

// 🚀 Transition
import TransitionButton from "../../components/Buttons/TransitionButton"
import Transition from "../../components/Animation/Transition"
import { moveCurrentPageOut } from "../../components/Animation/PageTransition"
import {
  moveCameraToLayer,
  moveCameraToPosition,
} from "../../components/Animation/CameraAnimation"
import Inhalt from "../../components/Contents/Inhalt"
import ProjektzielHeader from "../../components/Contents/Projektziel/ProjektzielHeader"
import ProjektzielTeaserImage from "../../components/Contents/Projektziel/ProjektzielTeaserImage"
import { isMobile } from "../../tools/isMobile"
import TOCParagraphButton from "../../components/Buttons/TOCParagraphButton"
import WYSIWYG from "../../components/ContentModules/WYSIWYG"
import { cleanStringForAnchor } from "../../tools/helper"

// Helper function to check if element is in viewport
function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    )
    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}

export default function Projektziel({ data, children, location }) {
  const sendActionToStore = useDispatch()
  const post = data.wpProjektziel
  const settings = data.wpModeSettings.acf_projektziel_settings
  const schwerpunkt = data.wpSchwerpunkt
  const mapColor = data.wpSchwerpunkt.acf_schwerpunkt_content.colorKartenFarbe
  const PREVIOUS_PATH = useSelector(state => state.previousPath)

  const CURRENT_PATH = useSelector(state => state.currentPath)

  sendActionToStore({
    type: "change/translatedSlug",
    payload: post.translated[0]?.slug,
  })

  sendActionToStore({
    type: "change/translatedSlug",
    payload: post.translated[0]?.slug,
  })

  // LAYER
  useEffect(() => {
    sendActionToStore({
      type: "change/dotEbene",
      payload: 2,
    })
  }, [sendActionToStore])

  // ! umbauen wenn WP ready
  function cleanUpWPMarkerData(data) {
    let cleanedUp = []
    if (data !== null) {
      data.map(item => {
        cleanedUp.push({
          x: item.x, // number
          y: item.y, // number
          key: item.key, // unique string
          type: item.type, // "static" | "interactive"
          visibility: item.visibility, // "top" | "bottom"
          icon: item.icon, // string
          canExpand: item.canexpand, // boolean
          title: item.title, // string
          image: item.image, // string
          link: {
            title: item.link.title, // string
            url: item.link.url, // string
          },
          expandedContent: item.expandedcontent,
        })
      })
    }
    return cleanedUp
  }

  // 🚀 Transitions Settings
  const [exit, setExit] = useState(false)
  const defaultInAnimation = location?.state
    ? location.state.direction
    : "right"
  const [AnimationDirection, setAnimationDirection] =
    useState(defaultInAnimation)

  // 🚀🧨 Transitions Redux Triggers
  const TRANSITION_INFO = useSelector(state => state.transition)
  useEffect(() => {
    if (TRANSITION_INFO.shouldExit && TRANSITION_INFO.direction) {
      setAnimationDirection(TRANSITION_INFO.direction)
      setExit(TRANSITION_INFO.shouldExit)
      sendActionToStore({
        type: `PageChangeTransition/ended`,
      })
    }
  }, [TRANSITION_INFO, sendActionToStore])

  // * Send ref to store
  const portalRef = useRef()

  // * Set project location
  const projectLocation =
    data.wpProjektziel?.acf_scene?.location?.acf_location?.location

  const [isSetup, setIsSetup] = useState(false)

  // * Set up store on first render
  useEffect(() => {
    if (!isSetup && portalRef.current) {
      if (projectLocation) {
        // * Initial move to position
        moveCameraToPosition(
          sendActionToStore,
          projectLocation.x,
          projectLocation.y,
          "default",
          70
        )

        // * Hide locations from map
        sendActionToStore({
          type: "change/locationsActive",
          payload: false,
        })

        // * Portal Ref
        sendActionToStore({
          type: "change/markerContainerPortal",
          payload: portalRef,
        })

        // * Active location to projekt
        sendActionToStore({
          type: "change/activeLocation",
          payload: projectLocation,
        })

        // * Get markers from wp
        const markers = cleanUpWPMarkerData(
          data.wpProjektziel.acf_scene.repeaterScene
        )

        // * Set initial visibility to top
        sendActionToStore({
          type: `change/projectScene`,
          payload: {
            topVisible: true,
            bottomVisible: false,
          },
        })

        // * Send project markers to store
        sendActionToStore({
          type: "change/map/marker",
          payload: markers,
        })

        // * Set background color
        // sendActionToStore({
        //   type: "change/map/groundColor",
        //   payload: mapColor,
        // })

        // * Set isSetup to true
        setIsSetup(true)
      }
    } else {
      // console.log("IS ALREADY SET UP")
    }
  }, [
    data.wpProjektziel.acf_scene.repeaterScene,
    isSetup,
    projectLocation,
    sendActionToStore,
  ])

  // * Set scene that is visible
  const ref_top = useRef()
  const isVisible_top = useOnScreen(ref_top)

  const ref_middle = useRef()
  const isVisible_middle = useOnScreen(ref_middle)

  const ref_bottom = useRef()
  const isVisible_bottom = useOnScreen(ref_bottom)

  console.log(
    "visibility refs",
    isVisible_top,
    isVisible_middle,
    isVisible_bottom
  )

  useEffect(() => {
    if (projectLocation && portalRef.current) {
      console.log("useEffect visibility ")

      // * Portal Ref
      // sendActionToStore({
      //   type: "change/markerContainerPortal",
      //   payload: portalRef,
      // })

      if (isVisible_top) {
        // moveCameraToLayer(sendActionToStore, "projektziele")

        console.log("VISBILITY TOP TRIGGER")

        sendActionToStore({
          type: "change/markerContainerPortal",
          payload: portalRef,
        })

        sendActionToStore({
          type: `change/projectScene`,
          payload: {
            topVisible: true,
            bottomVisible: false,
          },
        })
        moveCameraToPosition(
          sendActionToStore,
          projectLocation.x,
          projectLocation.y,
          "default",
          70
        )
      }

      if (isVisible_middle) {
        // moveCameraToLayer(sendActionToStore, "projektziele")
        moveCameraToPosition(
          sendActionToStore,
          projectLocation.x,
          projectLocation.y,
          "default",
          60
        )
        sendActionToStore({
          type: "change/locationsActive",
          payload: false,
        })
        sendActionToStore({
          type: `change/projectScene`,
          payload: {
            topVisible: true,
            bottomVisible: true,
          },
        })
      }

      if (isVisible_bottom) {
        if (isMobile()) {
          console.log("is mobile")
          // moveCameraToLayer(sendActionToStore, "karteMobile")
        } else {
          console.log("is mobile")
          moveCameraToLayer(sendActionToStore, "karte")
        }
        sendActionToStore({
          type: `change/projectScene`,
          payload: {
            topVisible: false,
            bottomVisible: false,
          },
        })
        sendActionToStore({
          type: "change/locationsActive",
          payload: true,
        })
      }
    }
  }, [
    isVisible_bottom,
    isVisible_middle,
    isVisible_top,
    projectLocation,
    sendActionToStore,
  ])

  let tocParagraphs

  if (post.acf_content.repeaterDetails?.length > 0) {
    // Neues Array mit allen Paragraphen für TOC
    tocParagraphs = post.acf_content.repeaterDetails?.concat(
      post.acf_additional_content.repeaterAdditionalContent
    )

    if (tocParagraphs.length > 0) {
      // Projekthebel an erster Stelle hinzufügen
      tocParagraphs.unshift({
        textSectionTitel:
          post.acf_projektziel_content?.groupProjekthebel?.textProjekthebelName,
      })
    }
  }

  return (
    <>
      <SEO title={post.title} />

      <ScrollTopButton />

      <Transition autoIn shouldExit={exit} direction={AnimationDirection}>
        <ProjectPageWrapper>
          <div ref={portalRef} id="portal" />
          <ProjectScene
            id="projectScene01"
            container="#projectScene01"
            markers={""}
          >
            <div ref={ref_top}></div>
          </ProjectScene>

          <ProjectPageCard>
            <Box position="absolute" top="-24px" left="80px">
              <TransitionButton
                text="← Zurück "
                link={PREVIOUS_PATH}
                targetPageInAnimation="left"
                onClick={() => {
                  sendActionToStore({
                    type: "change/map/marker",
                    payload: [],
                  })
                  moveCurrentPageOut(sendActionToStore, "right")
                }}
              />
            </Box>
            <TOCParagraphButton
              paragraphs={tocParagraphs}
              currentPath={CURRENT_PATH}
              title={settings.acf_global_settings?.textTocTitle}
            />

            <Box>
              <ProjektzielHeader
                projektName={
                  post.acf_projektziel_content.groupLeft.textProjektname
                }
                title={post.title}
                schwerpunkt={schwerpunkt.title}
                summary={post.acf_projektziel_content.groupLeft.textSummary}
                projektbeteiligteName={settings.textProjektbeteiligte}
                projektReferenz={
                  post.acf_projektziel_content.groupRight.selectProjectReference
                }
                projektbeteiligte={
                  post.acf_projektziel_content.groupRight
                    .textProjectParticipants
                }
                leitbegriffeName={settings.textKeywords}
                leitbegriffeBeschreibung={settings.textDescriptionKeywords}
                leitbegriffeExtendedBeschreibung={
                  settings.textAdditionalInfosKeywords
                }
                linkTextLeitbegriffe={settings.linktextLeitbegriffe}
                linkUrlLeitbegriffe={settings.linkLeitbegriffe}
                leitbegriffe={
                  post.acf_projektziel_content.groupRight.leitbegriffe
                }
              />

              <ProjektzielTeaserImage
                image={
                  post.acf_projektziel_content.groupLeft.imageFeaturedImage
                }
              />
            </Box>

            {/* Projekthebel */}

            <Box mb="64px">
              <Box
                width={true ? ["100%", "100%", "100%", "50%"] : "auto"}
                maxWidth={true ? "640px" : "auto"}
              >
                <Heading
                  as="h3"
                  size="md"
                  color="modeGray.500"
                  mb="8px"
                  id={
                    post.acf_projektziel_content?.groupProjekthebel
                      ?.textProjekthebelName != null
                      ? `${cleanStringForAnchor(
                          post.acf_projektziel_content?.groupProjekthebel
                            ?.textProjekthebelName
                        )}`
                      : ""
                  }
                >
                  {post.acf_projektziel_content?.groupProjekthebel
                    ?.textProjekthebelName || ""}
                </Heading>
              </Box>
              <Box
                width={true ? ["100%", "100%", "100%", "50%"] : "auto"}
                maxWidth={true ? "640px" : "auto"}
              >
                <WYSIWYG
                  content={
                    post.acf_projektziel_content?.groupProjekthebel
                      ?.wysiwygProjekthebelText
                  }
                />
              </Box>
            </Box>
            {/* 📰 Inhalt */}
            <Inhalt
              repeater={post.acf_content.repeaterDetails}
              smallWidth={true}
              projektZiel={true}
            />
          </ProjectPageCard>

          <ProjectScene>
            <div
              className="w-full h-full pointer-events-none"
              ref={ref_middle}
            ></div>
          </ProjectScene>

          <ProjectPageCard>
            {/* 📰 Inhalt */}
            <Inhalt
              repeater={post.acf_additional_content.repeaterAdditionalContent}
              smallWidth={true}
              galleryBelow={true}
              projektZiel={true}
            />
          </ProjectPageCard>

          <ProjectScene bottomScene>
            <div
              className="w-full h-full pointer-events-none"
              ref={ref_bottom}
            ></div>
          </ProjectScene>
          <Box h="25vh" />
        </ProjectPageWrapper>
      </Transition>
    </>
  )
}
export const query = graphql`
  query ProjektzielContentQuery(
    $lang: String!
    $slug: String!
    $schwerpunktSlug: String!
  ) {
    wpSchwerpunkt(slug: { eq: $schwerpunktSlug }) {
      title
      acf_schwerpunkt_content {
        colorKartenFarbe
      }
    }
    wpModeSettings(
      slug: { eq: "projektziele" }
      locale: { locale: { eq: $lang } }
    ) {
      acf_projektziel_settings {
        textKeywords
        textProjektbeteiligte
        textSummaryName
        textDescriptionKeywords
        textAdditionalInfosKeywords
        linkLeitbegriffe
        linktextLeitbegriffe
      }
    }
    wpProjektziel(slug: { eq: $slug }, locale: { locale: { eq: $lang } }) {
      title
      id
      translated {
        slug
      }
      acf_scene {
        repeaterScene {
          canexpand
          expandedcontent {
            content
            link {
              title
              url
            }
            title
          }
          icon
          image
          link {
            title
            url
          }
          title
          type
          visibility
          x
          y
        }
        location {
          acf_location {
            location {
              x
              y
            }
          }
          name
        }
      }
      acf_projektziel_content {
        groupRight {
          fieldGroupName
          textProjectParticipants
          leitbegriffe {
            id
            name
            description
            keyword_translation {
              enTranslation {
                name
                beschreibung
              }
            }
          }
          selectProjectReference {
            name
            tax_translation {
              enTranslation
            }
          }
        }
        groupLeft {
          textSummary
          textProjektname
          imageFeaturedImage {
            localFile {
              childImageSharp {
                fluid(quality: 60) {
                  src
                  srcSet
                }
              }
              publicURL
            }
            altText
          }
        }
        groupProjekthebel {
          textProjekthebelName
          wysiwygProjekthebelText
        }
      }
      acf_content {
        repeaterDetails {
          textSectionTitel
          flexibleLayoutContentarea {
            ... on WpProjektziel_AcfContent_repeaterDetails_FlexibleLayoutContentarea_AbsatzMitGallerie {
              wysiwygContent
              groupGallery {
                galleryGallery {
                  altText
                  caption
                  localFile {
                    childImageSharp {
                      fluid(quality: 60) {
                        src
                        srcSet
                      }
                    }
                    publicURL
                  }
                  acf_media {
                    textImageCredit
                  }
                }
                columns
              }
              fieldGroupName
              linkText
              linkUrl
            }
            ... on WpProjektziel_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Link {
              fieldGroupName
              linkText
              linkUrl
            }
            ... on WpProjektziel_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Absatz {
              wysiwygContent
              fieldGroupName
              linkText
              linkUrl
            }
            ... on WpProjektziel_AcfContent_repeaterDetails_FlexibleLayoutContentarea_VerwandteProjekte {
              fieldGroupName
              relationProject {
                ... on WpProjektziel {
                  id
                  slug
                  acf_projektziel_content {
                    groupLeft {
                      relationMobilitaetsschwerpunkt {
                        ... on WpSchwerpunkt {
                          id
                          slug
                        }
                      }
                      imageFeaturedImage {
                        altText
                        localFile {
                          childImageSharp {
                            fluid(quality: 60) {
                              src
                              srcSet
                            }
                          }
                          publicURL
                        }
                      }
                      textSummary
                      textProjektname
                    }
                    fieldGroupName
                  }
                }
              }
            }
            ... on WpProjektziel_AcfContent_repeaterDetails_FlexibleLayoutContentarea_VerwandteForschung {
              fieldGroupName
              relationForschung {
                ... on WpWissenschaft {
                  id
                  acf_forschung {
                    relationSchwerpunktthema {
                      ... on WpWissenschaftsschwerpunkt {
                        id
                        slug
                        title
                      }
                    }
                    projektbeschreibung {
                      fieldGroupName
                      textProjektbeschreibungText
                      textProjektbeschreibungTitle
                    }
                    imageFeatureImage {
                      altText
                      localFile {
                        childImageSharp {
                          fluid(quality: 60) {
                            src
                            srcSet
                          }
                        }
                        publicURL
                      }
                    }
                    groupProjektbeteiligte {
                      disziplin {
                        name
                      }
                    }
                  }
                  title
                  slug
                }
              }
            }
            ... on WpProjektziel_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Registerkarten {
              fieldGroupName
              repeaterRegisterkarten {
                textContent
                textTitle
              }
            }
            ... on WpProjektziel_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Download {
              fieldGroupName
              anhang {
                ... on WpProjektziel_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Download_Anhang_Datei {
                  buttonText
                  fieldGroupName
                  datei {
                    localFile {
                      publicURL
                    }
                  }
                }
                ... on WpProjektziel_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Download_Anhang_Video {
                  buttonText
                  fieldGroupName
                  video {
                    localFile {
                      publicURL
                    }
                  }
                }
                ... on WpProjektziel_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Download_Anhang_Url {
                  buttonText
                  fieldGroupName
                  url
                }
              }
            }
          }
          fieldGroupName
        }
      }
      acf_additional_content {
        repeaterAdditionalContent {
          textSectionTitel
          flexibleLayoutContentarea {
            ... on WpProjektziel_AcfAdditionalContent_repeaterAdditionalContent_FlexibleLayoutContentarea_AbsatzMitGallerie {
              wysiwygContent
              groupGallery {
                galleryGallery {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(quality: 60) {
                        src
                        srcSet
                      }
                    }
                    publicURL
                  }
                  acf_media {
                    textImageCredit
                  }
                }
                columns
              }
              linkText
              linkUrl
              fieldGroupName
            }
            ... on WpProjektziel_AcfAdditionalContent_repeaterAdditionalContent_FlexibleLayoutContentarea_Absatz {
              wysiwygContent
              fieldGroupName
              linkText
              linkUrl
            }
            ... on WpProjektziel_AcfAdditionalContent_repeaterAdditionalContent_FlexibleLayoutContentarea_Link {
              fieldGroupName
              linkText
              linkUrl
            }
            ... on WpProjektziel_AcfAdditionalContent_repeaterAdditionalContent_FlexibleLayoutContentarea_VerwandteProjekte {
              fieldGroupName
              relationProject {
                ... on WpProjektziel {
                  id
                  slug
                  acf_projektziel_content {
                    groupLeft {
                      relationMobilitaetsschwerpunkt {
                        ... on WpSchwerpunkt {
                          id
                          slug
                        }
                      }
                      imageFeaturedImage {
                        altText
                        localFile {
                          childImageSharp {
                            fluid(quality: 60) {
                              src
                              srcSet
                            }
                          }
                          publicURL
                        }
                      }
                      textSummary
                      textProjektname
                    }
                    fieldGroupName
                  }
                }
              }
            }
            ... on WpProjektziel_AcfAdditionalContent_repeaterAdditionalContent_FlexibleLayoutContentarea_VerwandteForschung {
              fieldGroupName
              relationForschung {
                ... on WpWissenschaft {
                  id
                  acf_forschung {
                    relationSchwerpunktthema {
                      ... on WpWissenschaftsschwerpunkt {
                        id
                        slug
                        title
                      }
                    }
                    projektbeschreibung {
                      fieldGroupName
                      textProjektbeschreibungText
                      textProjektbeschreibungTitle
                    }
                    imageFeatureImage {
                      altText
                      localFile {
                        childImageSharp {
                          fluid(quality: 60) {
                            src
                            srcSet
                          }
                        }
                        publicURL
                      }
                    }
                    groupProjektbeteiligte {
                      disziplin {
                        name
                      }
                    }
                  }
                  title
                  slug
                }
              }
            }
            ... on WpProjektziel_AcfAdditionalContent_repeaterAdditionalContent_FlexibleLayoutContentarea_Registerkarten {
              fieldGroupName
              repeaterRegisterkarten {
                textContent
                textTitle
              }
            }
            ... on WpProjektziel_AcfAdditionalContent_repeaterAdditionalContent_FlexibleLayoutContentarea_Download {
              fieldGroupName
              anhang {
                ... on WpProjektziel_AcfAdditionalContent_repeaterAdditionalContent_FlexibleLayoutContentarea_Download_Anhang_Datei {
                  buttonText
                  fieldGroupName
                  datei {
                    localFile {
                      publicURL
                    }
                  }
                }
                ... on WpProjektziel_AcfAdditionalContent_repeaterAdditionalContent_FlexibleLayoutContentarea_Download_Anhang_Video {
                  buttonText
                  fieldGroupName
                  video {
                    localFile {
                      publicURL
                    }
                  }
                }
                ... on WpProjektziel_AcfAdditionalContent_repeaterAdditionalContent_FlexibleLayoutContentarea_Download_Anhang_Url {
                  buttonText
                  fieldGroupName
                  url
                }
              }
            }
          }
          fieldGroupName
        }
      }
    }
  }
`
